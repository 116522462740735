import { create } from 'zustand'

export enum AvailableStatus {
  comingSoon = 'Coming Soon',
  open = 'Open',
}

export type AvailableStatusNames = (typeof AvailableStatus)[keyof typeof AvailableStatus]
export type AvailableStatusKeys = keyof typeof AvailableStatus

export type ReviewLinkState = {
  status: AvailableStatusNames | null
}

type ReviewLinkStore = ReviewLinkState & {
  setReviewLinkState: (state: ReviewLinkState) => void
}

const INITIAL_STATE: ReviewLinkState = {
  status: null,
}

export const useReviewLinkStore = create<ReviewLinkStore>()((set) => ({
  ...INITIAL_STATE,
  setReviewLinkState: (state: ReviewLinkState) => set(() => state),
}))
