import { groq } from 'next-sanity'
import { z } from 'zod'

import { LocationFields, LocationSchema } from '../../common/locationData'
import { mediaBuilder, MediaBuilderSchema } from '../builders/mediaBuilder'
import { basicContent, BasicContentSchema } from '../portableText/basicContent'
import { SanitySlugSchema } from '../validationPrimitives'

export const exhibitionSecondaryRanking = groq`
'secondaryRank': select(
  locations[0]->address.city == "New York" => select(
    locations[0]->address.addressLine match "West 19th Street" => select(
      locations[0]->address.addressLine match "519" => 1,
      locations[0]->address.addressLine match "525" => 2,
      locations[0]->address.addressLine match "533" => 3,
    ),
    locations[0]->address.addressLine match "West 20th" => 4,
    locations[0]->address.addressLine match "East 69th" => 5,
    6,
  ),
  locations[0]->address.city == "Los Angeles" => 20,
  locations[0]->address.city == "London" => 30,
  locations[0]->address.city == "Paris" => 40,
  locations[0]->address.city == "Hong Kong" => 50,
),
`

export const exhibitionSimpleFields = groq`
  _id,
  _type,
  title,
  slug,
  subtitle,
  summary[] {
    ${basicContent}
  },
  startDate,
  endDate,
  displayDate,
  status,
  eyebrow,
  reception,
`
export const ExhibitionStatusSchema = z.enum(['comingSoon', 'open', 'close'])

export const ExhibitionSimpleFieldsSchema = z.object({
  _id: z.string(),
  _type: z.literal('exhibitionPage'),
  title: z.string(),
  slug: SanitySlugSchema,
  subtitle: z.nullable(z.string()),
  summary: z.nullable(z.array(BasicContentSchema)),
  startDate: z.string(),
  endDate: z.string(),
  reception: z.nullable(z.string()),
  displayDate: z.nullable(z.string()),
  status: z.nullable(ExhibitionStatusSchema),
  eyebrow: z.nullable(z.string()),
})

export const exhibitionComplexFields = groq`
  "artists": artists[]->{
    fullName,
    affiliation,
    "artistPage": *[_type == "artistPage" && references(^._id)][0]{slug},
    firstName,
    lastName,
  },
  "locations": locations[]->{${LocationFields}},
  cardViewMedia {
    ${mediaBuilder}
  },
  heroMedia {
    ${mediaBuilder}
  },
  curator[]->{name},
`

const ExhibitionComplexFieldsSchema = z.object({
  artists: z.nullable(
    z.array(
      z.object({
        fullName: z.string(),
        affiliation: z.nullable(z.boolean()),
        artistPage: z.nullable(z.object({ slug: SanitySlugSchema })),
        firstName: z.nullable(z.string()),
        lastName: z.nullable(z.string()),
      })
    )
  ),
  locations: z.array(LocationSchema),
  cardViewMedia: z.nullable(MediaBuilderSchema),
  heroMedia: z.nullable(MediaBuilderSchema),
  curator: z.nullable(z.array(z.object({ name: z.string() }))),
})

export const exhibitionPageContent = groq`
  _type == 'exhibitionPage' => {
    ${exhibitionSimpleFields}
    ${exhibitionComplexFields}
  },
`

export const ExhibitionPageContentSchema = ExhibitionSimpleFieldsSchema.merge(
  ExhibitionComplexFieldsSchema
)

export const exhibitionCardFields = groq`
  "locations": locations[]->{${LocationFields}},
  cardViewMedia {
    ${mediaBuilder}
  },
  heroMedia {
    ${mediaBuilder}
  },
`

export const ExhibitionCardFieldsSchema = z.object({
  locations: z.array(LocationSchema),
  cardViewMedia: MediaBuilderSchema,
  heroMedia: MediaBuilderSchema,
})

export const ExhibitionCardContentSchema = ExhibitionSimpleFieldsSchema.merge(
  ExhibitionCardFieldsSchema
)

export type ExhibitionCardContentSchemaType = z.infer<typeof ExhibitionCardContentSchema>
