import { groq } from 'next-sanity'
import { z } from 'zod'

import {
  pageSEOFields,
  PageSEOFieldsExtendedSchema,
} from '@/sanity/queries/components/seo/pageSEOFields'

import { mediaBuilder } from '../builders/mediaBuilder'
import { basicContent, BasicContentSchema } from '../portableText/basicContent'
import {
  basicContentWithLinks,
  BasicContentWithLinksSchema,
} from '../portableText/basicContentWithLinks'
import { editorialContent, EditorialContentSchema } from '../portableText/editorialContent'
import { SanitySlugSchema } from '../validationPrimitives'

export const artworkContentFields = groq`
  _id,
  _type,
  "seo": {
    'canonical': coalesce(slug.current, null),
    ...seo {
    ${pageSEOFields}
    },
    (hideToggle == true) => {
      "robotsNoIndex": true,
      "robotsNoFollow": true
    }
  },
  title,
  displayCustomTitle,
  slug,
  displayDate,
  dateSelection,
  photos[]{
    ...,
    ${mediaBuilder}
  },
  artworkType,
  medium,
  inventoryId,
  framed,
  availability,
  artworkCTA,
  price,
  currency,
  dimensions[] {
    ${basicContent}
  },
  displayTitle[] {
    ${basicContent}
  },
  copyrightInformation[] {
    ${basicContent}
  },
  editionInformation[] {
    ${basicContent}
  },
  framedDimensions[] {
    ${basicContent}
  },
  additionalCaption[] {
    ${basicContentWithLinks}
  },
  salesInformation[] {
    ${basicContentWithLinks}
  },
  productInformation[] {
    ${basicContentWithLinks}
  },
  description[] {
    ${editorialContent}
  },
  backgroundColor,
  _createdAt,
  "product": shopify->store{ id, gid, variants[]->{ store { id, price, inventory { isAvailable }, gid } } },
  "artists": artists[]-> {fullName, artistPage->{slug}},
  hideToggle,
`
export const artworkContent = groq`
  _type == 'artwork' => {
    ${artworkContentFields}
  },
`

export const ArtworkTypeSchema = z.enum([
  'drawing',
  'mixedMedia',
  'painting',
  'photography',
  'print',
  'sculpture',
  'other',
])
export const ArtworkCurrencySchema = z.enum(['EUR', 'USD', 'GBP', 'HKD'])
export const ArtworkAvailabilitySchema = z.enum(['available', 'unavailable'])
export const ArtworkFramedSchema = z.enum(['Framed', 'Unframed', 'NotApplicable'])
export const ArtworkBackgroundColorSchema = z.enum(['transparent', 'lightGrey', 'darkGrey'])
const ArtworkCTASchema = z.object({
  CTA: z.string(),
  CTAText: z.string(),
  CTALink: z.string(),
  SecondaryCTALink: z.string(),
  SecondaryCTAText: z.string(),
  secondaryCTA: z.string(),
})
// TODO: Describe properly z.any() types
export const ArtworkContentSchema = z.object({
  _id: z.string(),
  _type: z.literal('artwork'),
  seo: PageSEOFieldsExtendedSchema,
  title: z.string(),
  inventoryId: z.nullable(z.string()),
  displayCustomTitle: z.nullable(z.boolean()),
  slug: SanitySlugSchema,
  artists: z.nullable(
    z.array(
      z.object({
        fullName: z.string(),
        artistPage: z.nullable(
          z.object({
            slug: SanitySlugSchema,
          })
        ),
      })
    )
  ),
  displayDate: z.string().nullish(),
  dateSelection: z.object({ year: z.string() }).nullish(),
  photos: z.nullable(z.array(z.any())),
  artworkType: ArtworkTypeSchema,
  medium: z.nullable(z.string()),
  framed: ArtworkFramedSchema,
  availability: z.nullable(ArtworkAvailabilitySchema),
  _createdAt: z.string(),
  artworkCTA: z.nullable(ArtworkCTASchema.partial()),
  price: z.number().nullish(),
  currency: ArtworkCurrencySchema.nullish(),
  dimensions: z.nullable(z.array(BasicContentSchema)),
  displayTitle: z.nullable(z.array(BasicContentSchema)),
  framedDimensions: z.nullable(z.array(BasicContentSchema)),
  additionalCaption: z.nullable(z.array(BasicContentWithLinksSchema)),
  editionInformation: z.nullable(z.array(BasicContentSchema)),
  copyrightInformation: z.nullable(z.array(BasicContentSchema)),
  salesInformation: z.nullable(z.array(BasicContentWithLinksSchema)),
  productInformation: z.nullable(z.array(BasicContentWithLinksSchema)),
  description: z.nullable(z.array(EditorialContentSchema)),
  product: z.nullable(z.any()),
  backgroundColor: z.nullable(ArtworkBackgroundColorSchema),
  hideToggle: z.nullable(z.boolean()),
})

export type ArtworkContentType = z.infer<typeof ArtworkContentSchema>
