import { signal } from '@preact/signals-react'
import * as Sentry from '@sentry/nextjs'
import type { SelectOption } from '@zwirner/design-system'
import { DzSelectorBanner, SelectorBannerVariant } from '@zwirner/design-system'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import type { ReactNode } from 'react'
import { useEffect } from 'react'

import {
  DRAFT_VIEW,
  DRAFTMODE_BANNER_ID,
  DRAFTMODE_SENTRY,
  EXIT,
  PREVIEW_EXIT_URL,
  REVIEW_MODE,
} from '@/common/constants/commonCopies'
import type { AvailableStatusKeys } from '@/store/reviewLinkStore'
import { AvailableStatus, useReviewLinkStore } from '@/store/reviewLinkStore'

export const SharePreviewState = signal<SelectOption | SelectOption[] | null>(null)
type ShareProps = {
  children: ReactNode | string
  status: string | null
  docType: string | undefined
  isShare?: boolean
}

export const PreviewBanner = ({ children, docType, status, isShare = false }: ShareProps) => {
  const pathname = usePathname()
  const router = useRouter()

  const exitPreviewURL = pathname
    ? `${PREVIEW_EXIT_URL}?path=${pathname}${docType ? `&type=${docType}` : ''}`
    : PREVIEW_EXIT_URL
  const setStatus = useReviewLinkStore((state) => state.setReviewLinkState)
  const statusByProps = (router?.query?.state as string) ?? status

  const currentSelectedValue = statusByProps
    ? {
        selectedValue: {
          id: statusByProps,
          title: AvailableStatus[statusByProps as AvailableStatusKeys],
          value: statusByProps,
          disabled: false,
        },
      }
    : {}

  useEffect(() => {
    if (statusByProps) {
      setStatus({ status: AvailableStatus[statusByProps as AvailableStatusKeys] })
    }
  }, [statusByProps, setStatus])

  useEffect(() => {
    const activeSpan = Sentry.getActiveSpan()
    activeSpan?.updateName(DRAFTMODE_SENTRY + location.pathname)
  }, [])

  return (
    <>
      <div data-accessible="always" className="contents">
        <DzSelectorBanner
          id={`${DRAFTMODE_BANNER_ID}${docType}`}
          title={!isShare ? DRAFT_VIEW : REVIEW_MODE}
          linkText={EXIT}
          LinkElement={Link}
          linkUrl={exitPreviewURL}
          variant={SelectorBannerVariant.BROWN}
          disableSelect={!isShare}
          onSelect={(state: SelectOption | SelectOption[]) => {
            if (!Array.isArray(state)) {
              const selectedValue = state.value as AvailableStatusKeys
              if (selectedValue !== null) {
                setStatus({ status: AvailableStatus[selectedValue] })
              }
            }
          }}
          selectOptions={
            Object.entries(AvailableStatus).map(([key, value]) => {
              return {
                id: key,
                title: value,
                value: key,
                disabled: false,
              }
            }) ?? []
          }
          {...currentSelectedValue}
        />
      </div>
      {children}
    </>
  )
}
