import { groq } from 'next-sanity'
import { z } from 'zod'

import { basicBlockFields, BasicFieldsSchema, ChildrenSchema } from './basicContent'
import { annotationFields, AnnotationFieldsSchema, StylesSchema } from './primitives'

export const editorialContent = groq`
  ${basicBlockFields}
  ${annotationFields}
`

export const EditorialContentSchema = BasicFieldsSchema.extend({
  style: StylesSchema,
  children: z.array(ChildrenSchema),
}).merge(AnnotationFieldsSchema)

export type EditorialContentType = z.infer<typeof EditorialContentSchema>
