import { groq } from 'next-sanity'
import { z } from 'zod'

import {
  basicContentWithLinks,
  BasicContentWithLinksSchema,
} from '../components/portableText/basicContentWithLinks'

export const LocationFields = groq`
  name,
  description[] {
    ${basicContentWithLinks}
  },
  timezone,
  type,
  url,
  phone,
  photos[],
  address,
  hours[] {
    day,
    availableTimes[] {
      from,
      to,
      _key,
      _type,
    },
  },
  email,
  specialHours[] {
    ${basicContentWithLinks}
  }
`

export const LocationSchema = z.any()

// rename to LocationSchema when LocationFields will be used everywhere as location query fields. LocationSchema = z.any() will be deleted after refactoring.
export const LocationSchemaDetailed = z.object({
  name: z.string(),
  description: z.nullable(z.array(BasicContentWithLinksSchema)),
  timezone: z.any(),
  type: z.any(),
  url: z.any(),
  phone: z.nullable(z.string()),
  photos: z.any(),
  address: z.any(),
  hours: z.nullable(
    z.array(
      z.object({
        day: z.string(),
        availableTimes: z.nullable(
          z.array(
            z.object({
              from: z.string(),
              to: z.string(),
              _key: z.string(),
              _type: z.string(),
            })
          )
        ),
      })
    )
  ),
  email: z.nullable(z.string().email()),
  specialHours: z.nullable(z.array(BasicContentWithLinksSchema)),
})

// TODO: Add this schema and resolve design system and container issues

// export const LocationSchema = z.object({
//   name: z.string(),
//   description: z.array(z.any()).optional().nullable(),
//   timezone: z.string(),
//   type: z.string(),
//   url: z.string().optional().nullable(),
//   phone: z.string().optional().nullable(),
//   photos: z.array(z.any().optional().nullable()),
//   address: z.object({
//     addressLine: z.string(),
//     addressLine2: z.string().optional().nullable(),
//     city: z.string(),
//     state: z.string().optional().nullable(),
//     country: z.string().optional().nullable(),
//     zipCode: z.string().optional().nullable(),
//     reverseZipAndCity: z.boolean().optional().nullable(),
//     geoLocation: z
//       .object({
//         lat: z.number(),
//         lng: z.number(),
//         alt: z.optional(z.number()),
//       })
//       .optional()
//       .nullable(),
//   }),
//   hours: z.array(
//     z.object({
//       day: z.string(),
//       availableTimes: z.array(
//         z.object({
//           from: z.string(),
//           to: z.string(),
//           _key: z.string(),
//           _type: z.string(),
//         })
//       ),
//     })
//   ),
// })

export type LocationSchemaType = z.infer<typeof LocationSchema>
export type LocationSchemaDetailedType = z.infer<typeof LocationSchemaDetailed>
