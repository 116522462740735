import { groq } from 'next-sanity'
import { z } from 'zod'

import { ctaBuilder, CTABuilderSchema } from './builders/ctaBuilder'
import { mediaBuilder, MediaBuilderSchema } from './builders/mediaBuilder'
import { basicContent, BasicContentSchema } from './portableText/basicContent'

// Must follow DzInterstitialTypeProps
export const dzInterstitialFields = groq`
  "_type": "dzInterstitial",
  'props': {
    title,
    mode,
    cta {
      ${ctaBuilder}
    },
    image {
      ${mediaBuilder}
    },
    tags,
    subtitle[] {
      ${basicContent}
    },
    eyebrow,
    registeredInterest {
      title,
      description[] {
        ${basicContent}
      },
    },
  }
`
export const dzInterstitialProps = groq`
  _type == 'dzInterstitial' => {
    ${dzInterstitialFields}
  },
`

const ModeSchema = z.enum(['Light', 'Dark'])
// props: DzInterstitialPropsDataSchema
export const DzInterstitialPropsDataSchema = z.object({
  title: z.nullable(z.string()),
  mode: z.nullable(ModeSchema),
  cta: z.nullable(CTABuilderSchema),
  tags: z.array(z.string()).nullish(),
  image: MediaBuilderSchema,
  subtitle: z.nullable(z.array(BasicContentSchema)),
  eyebrow: z.nullable(z.string()),
  registeredInterest: z.nullable(
    z.object({
      title: z.nullable(z.string()),
      description: z.nullable(z.array(BasicContentSchema)),
    })
  ),
})

export type ModeSchemaType = z.infer<typeof ModeSchema>
